import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SessionProvider } from "./contexts/SessionContext";
import LoadingWrapper from "./components/Loading/LoadingWrapper";

const Home = lazy(() => import("./home/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./service/Services"));
const SheetMetalServiceDetails = lazy(() =>
  import("./service/SheetMetalServiceDetails")
);
const HVACServiceDetails = lazy(() => import("./service/HVACServiceDetails"));
const FireProtectionServiceDetails = lazy(() =>
  import("./service/FireProtectionServiceDetails")
);
const ControlsAndAutomationServiceDetails = lazy(() =>
  import("./service/ControlsAndAutomationServiceDetails")
);
const CADDraftingServiceDetails = lazy(() =>
  import("./service/CADDraftingServiceDetails")
);
const AdditionalServiceDetails = lazy(() =>
  import("./service/AdditionalServiceDetails")
);
const ServiceDetailsRightSidebar = lazy(() =>
  import("./service/ServiceDetailsRightSidebar")
);
const Projects = lazy(() => import("./project/Projects"));
const Project1Details = lazy(() => import("./project/Project1Details"));
const Project2Details = lazy(() => import("./project/Project2Details"));
const Project3Details = lazy(() => import("./project/Project3Details"));
const News = lazy(() => import("./blog/News"));
const NewsDetails1 = lazy(() => import("./blog/NewsDetails1"));
const NewsDetails2 = lazy(() => import("./blog/NewsDetails2"));
const NewsDetails3 = lazy(() => import("./blog/NewsDetails3"));
const NewsDetails4 = lazy(() => import("./blog/NewsDetails4"));
const NewsDetails5 = lazy(() => import("./blog/NewsDetails5"));
const NewsDetails6 = lazy(() => import("./blog/NewsDetails6"));

const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));

const App = () => {
  return (
    <SessionProvider>
      <Router>
        <LoadingWrapper>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route
                path="/sheet-metal-service-details"
                element={<SheetMetalServiceDetails />}
              />
              <Route
                path="/hvac-service-details"
                element={<HVACServiceDetails />}
              />
              <Route
                path="/fire-protection-and-fire-alarm-service-details"
                element={<FireProtectionServiceDetails />}
              />
              <Route
                path="/controls-and-automation-service-details"
                element={<ControlsAndAutomationServiceDetails />}
              />
              <Route
                path="/cad-drafting-service-details"
                element={<CADDraftingServiceDetails />}
              />
              <Route
                path="/additional-service-details"
                element={<AdditionalServiceDetails />}
              />
              <Route path="/projects" element={<Projects />} />
              <Route
                path="/three-hills-water-treatment-plant"
                element={<Project1Details />}
              />
              <Route
                path="/agat-new-assay-and-prep-lab"
                element={<Project2Details />}
              />
              <Route
                path="/starlight-east-pump-station"
                element={<Project3Details />}
              />
              <Route path="/news" element={<News />} />
              <Route path="/news-details-1" element={<NewsDetails1 />} />
              <Route path="/news-details-2" element={<NewsDetails2 />} />
              <Route path="/news-details-3" element={<NewsDetails3 />} />
              <Route path="/news-details-4" element={<NewsDetails4 />} />
              <Route path="/news-details-5" element={<NewsDetails5 />} />
              <Route path="/news-details-6" element={<NewsDetails6 />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="*" element={<NoMAtch />} />
            </Routes>
          </Suspense>
        </LoadingWrapper>
      </Router>
    </SessionProvider>
  );
};

export default App;
